<template>
    <div>
        <div class="mb-3">
            <h5><i class="el-icon-aim text-primary mr-2"></i><i>Input number of image threshold will be expanded</i></h5>
        </div>
        <div class="d-flex align-items-center">
            <div class="mr-2">
                <h5 class="mb-0"><i class="fas fa-database text-primary mr-2"></i>Allow drive space <strong>(Z)</strong> up to</h5>
            </div>
            <div>
                <el-input-number v-model="maxStorageZ" controls-position="right" 
                                 :disabled="!enabledInput"
                                 @change="handleChange" :min="1" :max="9999"></el-input-number>
            </div>
            <div class="ml-2">
                <h5 class="mb-0"><strong>GB</strong> </h5>
            </div>
        </div>
        <div class="mt-2">
            <el-checkbox v-model="enabledInput"><span style="font-weight: normal;">Allow modify value</span></el-checkbox>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            element: {
                type: Object,
                default() {
                    return null;
                },
            },
            value: {
                type: String,
                default() {
                    return 1;
                },
            },
        },
        watch: {
            element() {
                this.maxStorageZ = parseInt(this.element.serviceDetails);
                if (this.maxStorageZ > 0) this.$emit('input', this.maxStorageZ.toString());
                else this.$emit('input', null);
            },
            maxStorageZ(){
                if (this.maxStorageZ > 0) this.$emit('input', this.maxStorageZ.toString());
                else this.$emit('input', null);
            },
        },
        data() {
            return {
                maxStorageZ: 100,
                enabledInput : false,
            };
        },
        created() {
            this.maxStorageZ = parseInt(this.element.serviceDetails);
        },
        methods: {
            handleChange(value) {
                console.log(value);
                if (this.maxStorageZ > 0) this.$emit('input', this.maxStorageZ.toString());
                else this.$emit('input', null);
            }
        }
    };
</script>